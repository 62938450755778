import React, { useState } from "react";
import Sidebar from "./Sidebar";
import ProductSection from "./ProductSection";
import "./AdminDashboard.css";

const AdminDashboard = () => {
  const [products, setProducts] = useState([]);
  const [showProducts, setShowProducts] = useState(false);

  // Show Products Section
  const handleShowProducts = () => {
    setShowProducts(true);
  };

  return (
    <div className="dashboard-container">
      {/* Sidebar */}
      <Sidebar handleShowProducts={handleShowProducts} />

      {/* Main Content Area */}
      <div className="dashboard-content">
        <h1>Admin Dashboard</h1>

        {/* Product Section */}
        {showProducts && (
          <ProductSection products={products} setProducts={setProducts} />
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
