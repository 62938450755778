import { MoreVert } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import axios from "axios";

const ProductList = ({ products, setProducts, handleEditProduct }) => {
    // data store state by Naphees

    const [activeProductId, setActiveProductId] = useState(null);
    // Get product
    const product_get = async() => {
        try {
            const response = await axios.get('http://localhost:4000/product', { withCredentials: true })
            setProducts(response.data);
            console.log(response.data);


        } catch (error) {
            console.error('Error fetching product:', error);
        }
    }

    const handleDeleteProduct = async(product_id) => {
        //  setProducts(products.filter((product) => product.id !== id));


        try {
            await axios.delete(`http://localhost:4000/product_delete/${product_id}`, { withCredentials: true });
            alert("Product deleted successfully!");
            product_get();
            // fetchImages(); // Refresh Product list
        } catch (err) {
            console.error("Error deleting product", err);
            alert("Error deleting product");
        }

    };

    const handleActionClick = (productId) => {
        setActiveProductId(activeProductId === productId ? null : productId);
    };


    return ( <
            div > {
                products.length > 0 && ( <
                    div className = "product-list-section" >
                    <
                    h2 > My Products < /h2> <
                    div className = "product-list" > {
                        products.map((product) => ( <
                                div className = "product-card"
                                key = { product.product_id } >
                                <
                                img src = { `http://localhost:4000${product.image}` }
                                alt = { product.name }
                                /> <
                                div className = "product-details" >
                                <
                                div className = "product-name" > { product.name } < /div> <
                                div className = "product-caption" > { product.description } < /div> <
                                div className = "product-category" >
                                <
                                strong > Category: < /strong> {product.category} < /
                                div > <
                                div className = "product-quantity" >
                                <
                                strong > Quantity: < /strong> {product.quantity} < /
                                div > <
                                div className = "product-price" >
                                <
                                strong > Price: < /strong> ₹{product.price} < /
                                div > <
                                /div> <
                                div className = "product-actions" >
                                <
                                MoreVert className = "action-icon"
                                onClick = {
                                    () => handleActionClick(product.product_id)
                                }
                                /> {
                                activeProductId === product.product_id && ( <
                                    div className = "dropdown-menu" >
                                    <
                                    Button onClick = {
                                        () => handleDeleteProduct(product.product_id)
                                    }
                                    variant = "outlined"
                                    color = "secondary" >
                                    Delete <
                                    /Button> <
                                    Button onClick = {
                                        () => handleEditProduct(product)
                                    }
                                    variant = "outlined"
                                    color = "primary" >
                                    Edit <
                                    /Button> < /
                                    div >
                                )
                            } <
                            /div> < /
                            div >
                        ))
                } <
                /div> < /
                div >
            )
        } <
        /div>
);
};

export default ProductList;