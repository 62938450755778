import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';


function Login() {

    const [inputs, setinputs] = useState({});
    const [dataError, setDataError] = useState("");
    const changehandle = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setinputs(inputs => ({...inputs, [name]: value }))
    }
    const navigate = useNavigate()
    const form_submit = async(event) => {
        event.preventDefault();

        axios.post('http://localhost:4000/login', inputs, { withCredentials: true })
            .then(res => {
                if (res.data.Status === "success") {
                    navigate('/dashboard');
                    console.log(res);
                } else {
                    setDataError('Invalid User Id or Password');
                    console.log(res);
                }

            })
            .then(err => {
                console.log(err)
                setDataError('Invalid User Id or Password');
            });

    }




    return ( <
        >
        <
        form onSubmit = { form_submit } >
        <
        div style = {
            { display: 'flex', margin: '3px', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', marginTop: '10%' }
        } >
        <
        div style = {
            { backgroundColor: '#2d3e50', padding: '3px', color: 'white', margin: '2px', borderRadius: '10px' }
        } >
        <
        div >
        <
        h1 style = {
            { textAlign: 'center', textWrap: 'wrap', margin: '2px', padding: '2px' }
        } > Log In to Get Started < /h1>

        <
        h2 style = {
            { textAlign: 'center', textWrap: 'wrap', margin: '2px', padding: '2px' }
        } > { dataError } < /h2>

        <
        /
        div >







        <
        div style = {
            { padding: '2px', margin: '2px' }
        } >

        <
        input type = 'text'
        name = 'user_id'
        required placeholder = 'Enter User Id'
        value = { inputs.user_id || '' }
        onChange = { changehandle }
        style = {
            { margin: '3px', padding: '3px', width: '100%', height: '30px' }
        }

        / > < /
        div > <
        div style = {
            { padding: '2px', margin: '2px' }
        } >
        <
        input type = 'password'
        name = 'password'
        value = { inputs.password || '' }
        onChange = { changehandle }
        required placeholder = 'Enter password'
        style = {
            { margin: '3px', padding: '3px', width: '100%', height: '30px' }
        }
        / >


        <
        /div> <
        div style = {
            { textAlign: 'center' }
        } >
        <
        button type = 'submit'

        style = {
            { backgroundColor: ' #00bcd4', padding: '3px', margin: '3px', width: '30%', borderRadius: '8px' }
        } >
        <
        h2 > Login < /h2> < /button > < /
        div >
        <
        div style = {
            { padding: '3px', margin: '3px', textAlign: 'center' }
        } >

        <
        h4 style = {
            { display: 'inline' }
        } > Don 't have an account ? </h4>

        <
        h4 style = {
            { display: 'inline' }
        } >
        <
        Link to = "/signup"
        style = {
            { color: 'red' }
        } >
        Sign Up <
        /
        Link >
        <
        /h4>




        <
        /
        div >

        <
        /div> 



        <
        /div>


        <
        /form> < / >
    )
}

export default Login;