import React from 'react'
import Dashboard from './Component/Dashboard';
import { Link, Route, Routes } from 'react-router-dom';
import Login from './Component/Login';
import Signup from './Component/Signup';


function App() {
    return ( <
        div >



        { /*  all routes declare here  */ } <
        Routes >
        <
        Route exact path = '/'
        element = { < Login / > } > < /Route> <
        Route path = '/signup'
        element = { < Signup / > } > < /Route>  <
        Route path = '/dashboard'
        element = { < Dashboard / > } > < /Route>




        <
        /Routes>




        <
        /div>
    )
}

export default App;