import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useState } from 'react';


function Signup() {

    const [inputs, setinputs] = useState({});
    const [dataError, setDataError] = useState("");
    const [dataSuccess, setDataSuccess] = useState("");
    const changehandle = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setinputs(inputs => ({...inputs, [name]: value }))
    }
    const navigate = useNavigate()
    const form_submit = async(event) => {
        event.preventDefault();

        axios.post('http://localhost:4000/signup', inputs, { withCredentials: true })
            .then(res => {
                if (res.data.Status === "success") {
                    // navigate('/dashboard');
                    setDataError('');
                    setDataSuccess('"Account created successfully. Please log in."');

                    console.log(res);
                } else {
                    setDataError('"An account with this email already exists."');
                    console.log(res);
                }

            })
            .then(err => {
                console.log(err)
                setDataError('"An account with this email already exists."');
            });

    }




    return ( <
        >
        <
        form onSubmit = { form_submit } >
        <
        div style = {
            { display: 'flex', margin: '3px', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', marginTop: '6%' }
        } >
        <
        div

        style = {
            { backgroundColor: '#2d3e50', padding: '3px', color: 'white', margin: '2px', borderRadius: '10px' }
        } >
        <
        div >
        <
        h1 style = {
            { textAlign: 'center', textWrap: 'wrap', margin: '2px', padding: '2px', marginTop: '6px' }
        } > Sign Up to Get Started < /h1>

        <
        h2 style = {
            { textAlign: 'center', textWrap: 'wrap', margin: '2px', padding: '2px' }
        } >

        { dataSuccess ? < div > { dataSuccess } < /div> :<div> { dataError }</div > }



        <
        /h2>

        <
        /
        div >


        <
        div style = {
            { padding: '2px', margin: '2px' }
        } >

        <
        input type = 'text'
        name = 'user_id'
        required placeholder = 'Enter User Id'
        value = { inputs.user_id || '' }
        onChange = { changehandle }
        style = {
            { margin: '3px', padding: '3px', width: '100%', height: '30px' }
        }

        / > < /
        div >





        <
        div style = {
            { padding: '2px', margin: '2px' }
        } >

        <
        input type = 'email'
        name = 'email'
        required placeholder = 'Enter Email'
        value = { inputs.email || '' }
        onChange = { changehandle }
        style = {
            { margin: '3px', padding: '3px', width: '100%', height: '30px' }
        }
        / > < /
        div >

        <
        div style = {
            { padding: '2px', margin: '2px' }
        } >
        <
        input type = 'password'
        name = 'password'
        value = { inputs.password || '' }
        onChange = { changehandle }
        required placeholder = 'Enter password'
        style = {
            { margin: '3px', padding: '3px', width: '100%', height: '30px' }
        }
        / > < /
        div >

        <
        div style = {
            { padding: '2px', margin: '2px' }
        } >
        <
        input type = 'number'
        name = 'phone'
        value = { inputs.phone || '' }
        onChange = { changehandle }
        required placeholder = 'Enter Phone Number'
        style = {
            { margin: '3px', padding: '3px', width: '100%', height: '30px' }
        }
        / > < /
        div >


        <
        div style = {
            { padding: '2px', margin: '2px' }
        } >
        <
        input type = 'text'
        name = 'company'
        value = { inputs.company || '' }
        onChange = { changehandle }
        required placeholder = 'Enter Company Name'
        style = {
            { margin: '3px', padding: '3px', width: '100%', height: '30px' }
        }
        / > < /
        div >


        <
        div style = {
            { padding: '2px', margin: '2px' }
        } >


        <
        select name = 'country'
        value = { inputs.country || '' }
        onChange = { changehandle }
        style = {
            { margin: '3px', padding: '3px', width: '100%', height: '30px' }
        }
        required >
        <
        option > India < /option> <
        option > USA < /option> <
        option > China < /option> <
        option > Nepal < /option> <
        option > Other < /option> < /
        select >

        <
        /div> 



        <
        div style = {
            { textAlign: 'center' }
        } >
        <
        button type = 'submit'
        style = {
            { backgroundColor: ' #00bcd4', padding: '3px', margin: '3px', width: '50%', borderRadius: '8px' }
        } > < h2 >
        Sign Up Today < /h2> < /button > < /
        div >
        <
        div style = {
            { padding: '3px', margin: '3px', textAlign: 'center' }
        } >

        <
        h4 style = {
            { display: 'inline' }
        } > Have an Account ? < /h4>

        <
        h4 style = {
            { display: 'inline' }
        } >
        <
        Link to = "/"
        style = {
            { color: 'red' }
        } >
        Login <
        /
        Link >
        <
        /h4>




        <
        /
        div >

        <
        /div> 



        <
        /div>


        <
        /form> < / >
    )
}

export default Signup;