import React from "react";
import AdminDashboard from "./AdminDashboard";
import "../App.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Dashboard() {
    const navigate = useNavigate();

    const [auth, setAuth] = useState(null);
    // user verify 
    useEffect(() => {
        axios.get('http://localhost:4000/verify', { withCredentials: true })
            .then(res => {
                if (res.data.Status === "success") {

                    console.log(res);
                    setAuth(true)

                } else {
                    navigate('/');

                }

            })
            .then(err => {
                console.log(err)
                if (err) {
                    navigate('/');
                }

            });





    }, [])
    return ( <
        div className = "App" >
        <
        AdminDashboard / >
        <
        /div>
    );
}

export default Dashboard;