import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import ProductList from "./ProductList";
import axios from "axios";
import { Description } from "@mui/icons-material";

const ProductSection = ({ products, setProducts }) => {
    // Get product
    const product_get = async() => {
            try {
                const response = await axios.get('http://localhost:4000/product', { withCredentials: true })
                setProducts(response.data);
                console.log(response.data);
                console.log(product);

            } catch (error) {
                console.error('Error fetching product:', error);
            }
        }
        // product_get function call by useEffect
    useEffect(() => {
        product_get();
    }, []);



    const [newProduct, setNewProduct] = useState({
        name: "",
        description: "",
        category: "",
        price: "",
        discount: "",
        netPrice: "",
        quantity: "",
        size: "",
        image: null,
    });
    const [imagePreview, setImagePreview] = useState(null);
    const [editingProduct, setEditingProduct] = useState(null);

    // Predefined categories for the dropdown
    const categories = [
        "Electronics",
        "Fashion",
        "Home Appliances",
        "Books",
        "Toys",
        "Groceries",
        "Beauty Products",
        "Sports Equipment",
    ];

    // Handle Image Upload
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageURL = URL.createObjectURL(file);
            setNewProduct((prev) => ({...prev, image: file }));
            setImagePreview(imageURL);
        }
    };

    // Handle Discount Calculation
    const handleDiscountChange = (event) => {
        const discount = parseFloat(event.target.value) || 0;
        const price = parseFloat(newProduct.price) || 0;
        const netPrice = price - (price * discount) / 100;

        setNewProduct((prev) => ({
            ...prev,
            discount,
            netPrice: netPrice.toFixed(2),
        }));
    };

    // Reset Form
    const resetForm = () => {
        setNewProduct({
            product_id: "",
            name: "",
            description: "",
            category: "",
            price: "",
            discount: "",
            netPrice: "",
            quantity: "",
            size: "",
            image: null,
        });
        setImagePreview(null);
    };

    // Add Product
    const handleAddProduct = async() => {
        if (!newProduct.name || !newProduct.description || !newProduct.image) {
            alert("Please fill out all fields and upload an image.");
            return;
        }

        try {
            const response = await axios.post('http://localhost:4000/upload', newProduct, {
                    headers: {
                        'content-Type': 'multipart/form-data',
                    },
                    withCredentials: true,

                })
                //alert('Image uploaded successfully!')
            console.log("Server Response:", response.data);

            // resetForm
            resetForm();
            //Refresh products
            product_get();

        } catch (error) {
            console.error('Error uploading the image:', error)
                // alert('Failed to upload image.');
        }



        // setProducts([...products, {...newProduct, id: Date.now() }]);

    };

    // Edit Product
    const handleEditProduct = (product) => {
        console.log(product);
        setEditingProduct(product);
        setNewProduct({
            product_id: product.product_id,
            name: product.name,
            description: product.description,
            category: product.category,
            price: product.price,
            discount: product.discount,
            netPrice: product.netPrice,
            quantity: product.quantity,
            size: product.size,
            image: product.image,
        });
        // setImagePreview(URL.createObjectURL(product.image));
    };

    // Save Edited Product
    const handleSaveEditedProduct = () => {
        if (!newProduct.name || !newProduct.description) {
            alert("Please fill out all fields ");
            return;
        }
        console.log(newProduct.name);
        console.log(newProduct);
        /*  setProducts(
             products.map((product) =>
                 product.id === editingProduct.id ? {...product, ...newProduct } :
                 product
             )
         ); */
        axios.put('http://localhost:4000/product_update', newProduct, { withCredentials: true })
            .then(response => {
                console.log('Product updated successfully:', response.data);
                alert('Product Updated Successfully!')
                setEditingProduct(null);
                resetForm();
                product_get(); //product List Refresh
            })
            .catch(error => {
                console.error('Error Update product:', error);
            });






    };

    return ( <
            div >
            <
            div className = "add-product-section" >
            <
            h2 > { editingProduct ? "Edit Product" : "Add Product" } < /h2> <
            div className = "add-product-form" >
            <
            TextField label = "Product Name"
            value = { newProduct.name }
            onChange = {
                (e) =>
                setNewProduct((prev) => ({
                    ...prev,
                    name: e.target.value,
                }))
            }
            className = "MuiTextField-root" /
            >
            <
            TextField label = "Description"
            value = { newProduct.description }
            onChange = {
                (e) =>
                setNewProduct((prev) => ({
                    ...prev,
                    description: e.target.value,
                }))
            }
            className = "MuiTextField-root" /
            >

            { /* Product Category Dropdown */ } <
            FormControl fullWidth >
            <
            InputLabel > Product Category < /InputLabel> <
            Select value = { newProduct.category }
            className = "MuiTextField-root2"
            onChange = {
                (e) =>
                setNewProduct((prev) => ({
                    ...prev,
                    category: e.target.value,
                }))
            }
            label = "Product Category" > {
                categories.map((category) => ( <
                    MenuItem key = { category }
                    value = { category } > { category } <
                    /MenuItem>
                ))
            } <
            /Select> < /
            FormControl >

            <
            TextField label = "Price"
            type = "number"
            value = { newProduct.price }
            onChange = {
                (e) =>
                setNewProduct((prev) => ({
                    ...prev,
                    price: e.target.value,
                }))
            }
            className = "MuiTextField-root" /
            >
            <
            TextField label = "Discount (%)"
            type = "number"
            value = { newProduct.discount }
            onChange = { handleDiscountChange }
            className = "MuiTextField-root" /
            >
            <
            TextField label = "Net Price"
            type = "number"
            value = { newProduct.netPrice }
            InputProps = {
                { readOnly: true }
            }
            className = "MuiTextField-root" /
            >
            <
            TextField label = "Quantity"
            type = "number"
            value = { newProduct.quantity }
            onChange = {
                (e) =>
                setNewProduct((prev) => ({
                    ...prev,
                    quantity: e.target.value,
                }))
            }
            className = "MuiTextField-root" /
            >
            <
            TextField label = "Size"
            value = { newProduct.size }
            onChange = {
                (e) =>
                setNewProduct((prev) => ({
                    ...prev,
                    size: e.target.value,
                }))
            }
            className = "MuiTextField-root" /
            >
            <
            div style = {
                {
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                }
            } >


            {
                editingProduct ? "" :
                    <
                    Button variant = "contained"
                component = "label"
                className = "upload-button" >
                Upload Image <
                input type = "file"
                accept = "image/*"
                hidden onChange = { handleImageChange }
                /> < /
                Button >
            }



            <
            Button variant = "contained"
            color = "primary"
            onClick = {
                editingProduct ? handleSaveEditedProduct : handleAddProduct
            }
            className = "add-product-button" > { editingProduct ? "Save Changes" : "Add Product" } <
            /Button> < /
            div > <
            /div> {
            imagePreview && ( <
                div className = "product-preview" >
                <
                img src = { imagePreview }
                alt = "Product Preview" / >
                <
                /div>
            )
        } <
        /div> <
    ProductList products = { products }
    setProducts = { setProducts }
    handleEditProduct = { handleEditProduct }
    /> < /
    div >
);
};

export default ProductSection;