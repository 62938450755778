import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ handleShowProducts }) => {


        // fetch user profile
        const [profile, setProfile] = useState('');

        useEffect(() => {
            axios.get('http://localhost:4000/profile', { withCredentials: true })
                .then(res => {
                    if (res) {

                        console.log(res);

                        setProfile(res.data);
                        console.log(profile);
                    } else {


                        console.log(res.err);

                    }

                })
                .then(err => console.log(err));
        }, [])



        // user logout
        const navigate = useNavigate();
        const logout = () => {
            axios.get('http://localhost:4000/logout', { withCredentials: true })
                .then(res => {
                    console.log(res.data);

                    navigate('/');

                }).catch(err => console.log(err));

        }



        return ( <
                div className = "dashboard-sidebar" >

                <
                h1 >

                {
                    profile ? < div style = {
                        { margin: '5px' }
                    } > {
                        profile.map((key) => {
                                return ( < div > { key.company } < /div>)
                                })
                        }

                        <
                        /div>:''}

                        <
                        /h1>


                        <
                        h3 style = {
                            { margin: '5px' } } > Admin Dashboard < /h3> <
                        ul >
                        <
                        li onClick = { handleShowProducts } > My Products < /li> <
                        li > Settings < /li> <
                        li onClick = { logout } > Logout < /li> < /
                        ul > <
                        /div>
                    );
                };

                export default Sidebar;